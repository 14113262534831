<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="admins"
          :headers="headers"
          show-select
          item-key="email"
          :loading="loading"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn color="error" small :loading="loading" @click="deleteAll">
              Revoke Admin Role
            </v-btn>
            <v-btn color="primary" small @click="makeAdminDialog = true">
              Make New Admin
            </v-btn>
          </template>
          <template #item.fullname="{ item }">
            <span
              class="cursor-pointer primary--text"
              @click="openUserDialog(item.email)"
            >
              {{ item.fullname }}
            </span>
          </template>
        </ReportTable>
        <v-dialog
          v-model="makeAdminDialog"
          max-width="300px"
          persistent
          @keydown.esc="close"
        >
          <ValidationObserver ref="addAdmin" v-slot="{ invalid, passes }">
            <v-form @submit.prevent="passes(doMakeAdmin)">
              <v-card>
                <v-card-title> Make Admin </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                  <ECol cols="12" sm="12" md="12" class="pa-0">
                    <Autocomplete
                      v-model="selectedOwner"
                      item-value="id"
                      item-text="email"
                      label="User Email"
                      resource="users"
                      rules="required"
                    />
                  </ECol>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close">Cancel</v-btn>
                  <v-btn color="primary" text :disabled="invalid" type="submit">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </ValidationObserver>
        </v-dialog>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import AdminHeaders from "@/components/admins/adminHeaders"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useUserStore } from "@/stores/users"

export default {
  components: {
    ReportTable,
    Autocomplete,
  },
  data: () => {
    return {
      loading: false,
      isDeletionFailed: false,
      headers: AdminHeaders,
      makeAdminDialog: false,
      selectedOwner: null,
      filterFields: {
        fullname: {
          component: "TextFieldSearchFilter",
        },
        email: {
          component: "TextFieldSearchFilter",
        },
      },
    }
  },
  computed: {
    ...mapStores(useReportStore),
    provider() {
      return AdminApi.users.getAdmins
    },
  },
  methods: {
    openUserDialog(email) {
      useUserStore().openDialog(email)
    },
    async deleteAll() {
      if (this.reportStore.selectedItems.length === 0) {
        this.$notifications.warn("At least select one admin!")

        return
      }
      if (
        await this.$confirmDialog.open({
          title: "Revoke admin role ?",
          message: "This will revoke admin role from selected admin(s)!",
        })
      ) {
        this.loading = true
        for (const user of this.reportStore.selectedItems) {
          await this.doDelete(user.email)
        }
        this.loading = false
        this.reportStore.selectedItems = []
        if (this.isDeletionFailed) {
          this.isDeletionFailed = false

          return
        }
        this.$notifications.success("User(s) deleted successfully.")
      }
    },
    async doDelete(email) {
      try {
        await AdminApi.users.deleteAdmin(email)
        this.reportStore.items = this.reportStore.items.filter(
          (item) => item.email !== email
        )
      } catch (error) {
        this.isDeletionFailed = true
        this.$notifications.error({ text: "Delete Admin failed!", error })
      }
    },
    async doMakeAdmin() {
      if (this.selectedOwner?.email) {
        try {
          await AdminApi.users.makeAdmin(this.selectedOwner.email)
          this.$notifications.success(
            `${this.selectedOwner.email} has been added as Admin.`
          )
          this.close()
          this.reportStore.searchFilters = {}
        } catch (error) {
          this.$notifications.error({
            text: "Cannot grant user admin access!",
            error,
          })
        }
      }
    },
    close() {
      this.makeAdminDialog = false
      this.selectedOwner = null
      this.$refs.addAdmin.reset()
    },
  },
}
</script>
