export default [
  {
    value: "fullname",
    text: "Name",
    togglable: true,
    visible: true,
    width: 150,
  },
  {
    value: "email",
    text: "Email",
    togglable: true,
    visible: true,
    width: 200,
  },
  {
    value: "createdAt",
    text: "Created At",
    togglable: true,
    visible: true,
    width: 150,
  },
  {
    value: "updatedAt",
    text: "Updated At",
    togglable: true,
    visible: true,
    width: 150,
  },
  {
    value: "lastLoginAt",
    text: "Last Sign in At",
    togglable: true,
    visible: true,
    width: 150,
  },
  {
    value: "signInCount",
    text: "Sign in Count",
    sortable: false,
    togglable: true,
    visible: true,
    width: 100,
    align: "center",
  },
  {
    value: "lastSignInIp",
    text: "Last Sign in IP",
    sortable: false,
    togglable: true,
    visible: true,
    width: 80,
    align: "center",
  },
]
